import { useState } from 'react';
import { Button, Card, Col, Input, Row, Space } from 'antd';

import { Link } from 'react-router-dom';
import { routerLinks } from '../helpers/links';
import { translate } from '../../Listings/translations/generic';

export const ForgotPassword = () => {
  const [, setUserName] = useState('');

  const getPassword = () => {
    console.log('should implement')
  };

  return (
    <Card
      title={translate('Forgot password')}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <h4>{translate('pleaseEnterYourEmail')}</h4>
          <Input
            autoFocus
            type="text"
            placeholder={translate('pleaseEnterYourEmail')}
            onChange={e => setUserName(e.target.value)}
            onKeyUp={e => e.code === 'Enter' && getPassword()}
          />
        </Col>
        <Col span={24}>
          <Space direction="horizontal" align="center" >
            <Button
              type="primary"
              onClick={getPassword}
            >Login
            </Button>
            <Link to={routerLinks.login()}>{translate('proceedToLogin')}</Link>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};
