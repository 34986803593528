import { Row, Col, Input, Select, Space } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { updateEditListingKeyVal } from "../ReduxSlices/ListingsSlice";

const { Option } = Select;

export const BasicDetailsEditContainer = ({ listingId }) => {
  const BasicDetails = useSelector(state => state.listings.editListing[listingId].BasicDetails)
  const dispatch = useDispatch();

  const updateKeyValue = e => {
    dispatch(updateEditListingKeyVal({
      listingId,
      groupId: 'BasicDetails',
      fieldId: e.target.name,
      value: e.target.value
    }))
  }

  return (
    <>
      <Col xs={24} md={12} xl={8} xxl={6}>
        <h3>Short Description</h3>
        <Input type="text" name="ShortDescription" value={BasicDetails.ShortDescription} onChange={updateKeyValue} />
      </Col>
      <Col xs={24} md={12} xl={8} xxl={6}>
        <h3>Code</h3>
        <Input type="text" name="Code" value={BasicDetails.Code} onChange={updateKeyValue} />
      </Col>
      <Col xs={24} md={12} xl={8} xxl={6}>
        <h3>Date</h3>
        <Input type="date" name="DateOfEntry" value={BasicDetails.DateOfEntry} onChange={updateKeyValue} />
      </Col>
      <Col xs={24} md={12} xl={8} xxl={6}>
        <h3>Collaborators</h3>
        <Input type="text" name="Collaborators" value={BasicDetails.Collaborators} onChange={updateKeyValue} />
      </Col>
    </>
  )
}


export const PropertyDetailsEditContainer = ({ listingId }) => {
  const PropertyDetails = useSelector(state => state.listings.editListing[listingId].PropertyDetails)
  const dispatch = useDispatch();

  const updateKeyValue = e => {
    dispatch(updateEditListingKeyVal({
      listingId,
      groupId: 'PropertyDetails',
      fieldId: e.target.name,
      value: e.target.value
    }))
  }

  const handleChange = value => updateKeyValue({
    target: {
      name: 'SaleType',
      value: value
    }
  })

  return (
    <>
      <Col xs={24} md={12} xl={8} xxl={6}>
        <h3>SaleType</h3>
        <Select
          // mode="multiple"
          style={{ width: '100%' }}
          name="Saletype"
          value={PropertyDetails.SaleType}
          defaultValue={'sale'}
          onChange={handleChange}
          optionLabelProp="label"
        >
          <Option value="sale" label="Sale">
            <Space>
              Sale
            </Space>
          </Option>
          <Option value="rent" label="Rent">
            <Space>
              Rent
            </Space>
          </Option>
          <Option value="exchange" label="Exchange">
            <Space>
              Exchange
            </Space>
          </Option>
        </Select>
      </Col>
      <Col xs={24} md={12} xl={8} xxl={6}>
        <h3>Price</h3>
        <Input
          type="number"
          name="Price"
          min={1}
          value={PropertyDetails.Price}
          onChange={updateKeyValue}
        />
      </Col>
      <Col xs={24} md={12} xl={8} xxl={6}>
        <h3>Price</h3>
        <Input
          type="number"
          name="Size"
          main={1}
          value={PropertyDetails.Size}
          onChange={updateKeyValue}
        />
      </Col>
    </>
  )
}
