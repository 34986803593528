import { createSlice } from '@reduxjs/toolkit'
import * as SchemaDefinitions from '../helpers/definitions';
import { Schema } from '../helpers/Schema';

let initialState = {
  openSearch: false,
  searchFilter: {
    matchAll: true
  },
  executeSearch: true,
  editListing: []
}

//update searchFilter with the SchemaDefinitions
const resetSearchFilterState = () =>
  Object.keys(SchemaDefinitions)
    .forEach(key => initialState.searchFilter[key] = {})

const resetEditState = () =>
  Object.keys(SchemaDefinitions)
    .forEach(key => {
      initialState.editListing = {};
    })

const initialize = () => {
  resetEditState();
  resetSearchFilterState();
  initialState.editListing = {} ;
}

initialize();

export const ListingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    openSearch: state => {
      state.openSearch = true;
    },
    closeSearch: state => {
      state.openSearch = false;
    },
    setMatchingMethod: (state, action) => {
      state.searchFilter.matchAll = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.searchFilter[action.payload.groupId][action.payload.fieldId] = action.payload.value;
    },
    setEditListing: (state, action) => {
      state.editListing[action.payload.listingId] = Schema({ ...action.payload.data });
    },
    updateEditListingKeyVal: (state, action) => {
      state.editListing[action.payload.listingId][action.payload.groupId][action.payload.fieldId] = action.payload.value;
    },
    resetSearchFilter: state => {
      const _searchFilter = {};
      Object.keys(SchemaDefinitions).forEach(key => _searchFilter[key] = {});
      state.searchFilter = _searchFilter;
    },
    resetEditState: state => {

    },
    setExecuteSearch: (state, action) => state.executeSearch = action.payload
  },
})

// Action creators are generated for each case reducer function
export const {
  closeSearch,
  openSearch,
  resetSearchFilter,
  setEditListing,
  setMatchingMethod,
  setSearchFilter,
  setExecuteSearch,
  updateEditListingKeyVal
} = ListingsSlice.actions

export default ListingsSlice.reducer