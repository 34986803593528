import { Col, Card } from "antd";
import { Link } from "react-router-dom";
import { routerLinks } from "../helpers/links";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { openSearch } from "../ReduxSlices/ListingsSlice";

export const InsertOrSearch = () => {
  const dispatch = useDispatch();
  return (
    <Col
      key={'create-new'}
      xs={24}
      sm={12}
      md={8}
      xl={6}
      xxl={4}
    >
      <Card
        title={'Create or Search ?'}
        actions={[
          <Link to={routerLinks.edit('new')}>
            <FaPlus />
          </Link>,
          <FaSearch onClick={() => dispatch(openSearch(true))} />
        ]}
      />
    </Col>
  )
}
