import { Routes, Route } from 'react-router-dom';
import { routerLinks } from './helpers/links';
import { IndexPage } from './Pages/index';

export const RouterDashboard = () => (
  <Routes key="dashboard">
    <Route path={routerLinks.dashboard} element={<IndexPage />} />
  </Routes>
)

