import { Routes, Route } from 'react-router-dom';
import EditListingPage from './Pages/EditListingPage';
import SearchListingPage from './Pages/SearchListingPage';
import { routerLinks } from './helpers/links';

export const RouterProperties = () => (
  <Routes>
    <Route path={routerLinks.search()} element={<SearchListingPage />} />
    <Route path={routerLinks.edit(':listingId')}  element={<EditListingPage />} />
  </Routes>
)

