import { useState, useEffect } from 'react';
import { Collapse } from 'antd';

import { CheckBoxSearch, SliderSearch } from '../Components/SearchComponents';
import * as Translations from '../translations/definitions';

const { Panel } = Collapse;

export const SearchFormBuilder = ({
  header,
  headerVisible = true,
  groupId,
  initializationFunction
}) => {
  const [trueFalseFields, setTrueFalseFields] = useState([]);
  const [rangeFields, setRangeFields] = useState([]);
  const [labels, setLabels] = useState({});

  useEffect(() => {
    const fields = initializationFunction();

    setTrueFalseFields(
      Object.keys(fields)
        .filter(key => typeof fields[key] === 'boolean')
        .map(key => ({
          key,
          value: fields[key]
        }))
    );

    setRangeFields(
      Object.keys(fields)
        .filter(key => typeof fields[key] === 'number')
        .map(key => ({
          key,
          value: fields[key]
        }))
    )

    try {
      Translations[header]()
        .then(translatedLabels => setLabels(translatedLabels))
    } catch (e) {
      console.log(header, e)
    }

  }, []);

  return (
    headerVisible
      ? <Collapse style={{marginBottom: '0.2rem'}}>
        <Panel header={labels.header}>
          {rangeFields && <SliderSearch groupId={groupId} labels={labels} items={rangeFields} />}
          {trueFalseFields && <CheckBoxSearch groupId={groupId} labels={labels} items={trueFalseFields} />}
        </Panel>
      </Collapse>
      :
      <>
        {rangeFields && <SliderSearch groupId={groupId} labels={labels} items={rangeFields} />}
        {trueFalseFields && <CheckBoxSearch groupId={groupId} labels={labels} items={trueFalseFields} />}
      </>
  )
}