import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  rightDrawer: false,
  tableOrCards: 'cards',
}

export const layoutSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRightDrawer: (state, action) => {
      state.rightDrawer = action.payload; 
    },
    setTableOrCards: (state, action) => {
      state.tableOrCards = action.payload; 
    }
  },
})

// Action creators are generated for each case reducer function
export const { setRightDrawer, setTableOrCards } = layoutSlice.actions

export default layoutSlice.reducer