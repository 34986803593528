import { useSelector } from 'react-redux';
import { Alert } from 'antd';
import { YouAreAlreadyLoggedIn } from '../Components/YouAreAlreadyLoggedIn';
import { Login } from '../Components/Login';

export const LoginContainer = () => {
  const loggedIn = useSelector(state => state.auth.token !== null);

  return (
    loggedIn
      ? <Alert message={<YouAreAlreadyLoggedIn />} type="info" />
      : <Login />
  )
}
