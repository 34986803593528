const isLiveServer = (fake) => fake ? fake : document.location.href.includes('sekdev');

export const servers = {
  appServer: isLiveServer(false) ? 'https://nxt.server004.sekdev.com' : 'http://localhost:3000',
  authServer: isLiveServer(false) ? 'https://nxt-auth-v2.server004.sekdev.com' : 'http://localhost:5001',
  registrationMailServer: isLiveServer(false) ? 'https://nxt-mailserver.server004.sekdev.com' : 'http://localhost:5004',
}

export const conf = {
  appUrl: servers.appServer,
  authServer: servers.authServer,
  createUserUrl: servers.authServer+'/user',
  activationUrl: servers.authServer+'/activate',  
  registrationMailUrl: servers.registrationMailServer+'/registrations/nxt',
}