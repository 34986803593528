import { Button } from "antd";
import { Link } from "react-router-dom";
import { routerLinks } from "../../mainConfig";

export const IndexPage = () => {
  return (
    <div>
      Dashboard
      <Link to={routerLinks.listings.search()}>
        <Button>Listings</Button>
      </Link>
    </div>
  );
};