import { useDispatch } from "react-redux";
import { clearToken } from "../ReduxSlices/AuthSlice";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

export const Logout = () => {
  const dispatch = useDispatch();
  dispatch(clearToken());

  return (
    <Result
      icon={<FaWhatsapp />}
      title="You are now logged out"
      extra={
        <Link to="/">
          <Button type="primary">Next</Button>
        </Link>
      }
    />
  )
}