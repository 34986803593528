import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';
import { routerLinks } from '../helpers/links';

export const YouAreAlreadyLoggedIn = () =>
  <Result
    status="403"
    title="403"
    subTitle="Oops, it seems you are already logged in"
    extra={
      <Link to={routerLinks.login()}>
        <Button type="primary">Back Home</Button>
      </Link>
    }
  />