const translations = {
    en: {
      geolocationSuccess: 'Location succesfully found',
      geolocationError: 'Oops, could find the location',
      geolocationSearchHeader: 'Search maps',
      problemSaving: 'There was a problem saving the item',
      errorFetching: 'Ooops, there was a problem getting the item',
      succesfullySaved: 'Succesfully saved',
      errorFetchingResults: 'Could not load results, please check your internet connection',
      succesfullyRemoved: 'Succesfully removed',
      couldNotRemoveItem: 'Could not remove item',
      searchComponentHeader: 'Search',
      yes: 'Yes',
      no: 'No',
      cancel: 'Cancel',
      save: 'Save',
      login: 'Login',
      register: 'Register',
    },
    el: {
      geolocationSuccess: 'Η τοποθεσία βρέθηκε με επιτυχία',
      geolocationError: 'Ούπς, δεν βρέθηκε η τοποθεσία. Πληκτρολογήστε διεύθυνση, πόλη και ΤΚ για μεγαλύτερη ακρίβεια',
      geolocationSearchHeader: 'Αναζήτηση στον χάρτη',
      problemSaving: 'Ουυυυπς, παρουσιάσθηκε πρόβλημα κατα την αποθήκευση',
      errorFetching: 'Ουυυυπς, δεν ήταν δυνατή η ανάκτηση της εγγραφής',
      succesfullySaved: 'Η εγγραφή αποθηκεύθηκε με επιτυχία',
      errorFetchingResults: 'Ουυυυπς, παρουσιάσθηκε πρόβλημα κατα την λήψη των αποτελεσμάτων',
      succesfullyRemoved: 'Η εγγραφή διαγράφηκε με επιτυχία',
      couldNotRemoveItem: 'Δέν ήταν δυνατή η διαγραφή',
      searchComponentHeader: 'Αναζήτηση',
      yes: 'Ναί',
      no: 'Όχι',
      cancel: 'Ακύρωση',
      save: 'Αποθήκευση',
      login: 'Σύνδεση',
      register: 'Εγγραφή',
    }
  };
   
  export const translate = (key, langId) => {
    if ((!langId) && (!localStorage.langId)) {
      if (!translations['en'][key]) return '*'+key;
      return translations['en'][key];
    }
    return translations[localStorage.langId][key];
  }