import { 
  serviceLinks as serviceLinksListings,
  routerLinks as routerLinksListings,
} from './Listings/helpers/links';

import { 
  routerLinks as routerLinksAuth,
} from './Auth/helpers/links';

export const config = {
  gMapsKey: 'AIzaSyBaewWFSHjrziwHM0m83PFdaFEApzPOwqM',
}

export const routerLinks = {
  auth: { ...routerLinksAuth },
  listings: { ...routerLinksListings },
}

export const serviceLinks = {
  geocodingSearch: ({ address }) => `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${config.gMapsKey}`,
  listings: { ...serviceLinksListings },
}