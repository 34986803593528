import { Button, Result } from 'antd';

export const WelcomePage = () => (
  <Result
    title="Welcome"
    extra={
      <Button type="primary" key="console">
        This is under development
      </Button>
    }
  />

);