import { useState, useEffect } from 'react';
import { Collapse } from 'antd';

import * as Translations from '../translations/definitions';
import { CheckBoxEdit, SliderEdit } from '../Components/InputComponents';

const { Panel } = Collapse;

export const EditFormBuilder = ({ header, listingId, groupId, initializationFunction }) => {
  const [trueFalseFields, setTrueFalseFields] = useState([]);
  const [rangeFields, setRangeFields] = useState([]);
  const [labels, setLabels] = useState({});
  useEffect(() => {
    const fields = initializationFunction();

    setTrueFalseFields(
      Object.keys(fields)
        .filter(key => typeof fields[key] === 'boolean')
        .map(key => ({
          key,
          value: fields[key]
        }))
    );

    setRangeFields(
      Object.keys(fields)
        .filter(key => typeof fields[key] === 'number')
        .map(key => ({
          key,
          value: fields[key]
        }))
    )

    try {
      Translations[header]()
        .then(translatedLabels => setLabels(translatedLabels))
    } catch (e) {
      console.log(header, e)
    }

  }, []);

  return (
    <Collapse>
      <Panel header={labels.header} >
        {rangeFields && <SliderEdit
          listingId={listingId}
          groupId={groupId}
          labels={labels}
          items={rangeFields}
        />}
        {trueFalseFields && <CheckBoxEdit
          listingId={listingId}
          groupId={groupId}
          labels={labels}
          items={trueFalseFields}
        />}
      </Panel>
    </Collapse>
  )
}