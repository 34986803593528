import { useEffect, useState } from "react"
import { fetchListingById } from "../api/ListingsAPI"
import { useDispatch } from "react-redux";
import { setEditListing } from "../ReduxSlices/ListingsSlice";
import { Card, Result } from 'antd'
export const FetchContainer = ({ params: { type, listingId = null, payload = undefined } }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (type === 'get') {
      if (listingId) {
        fetchListingById({ listingId })
          .then(r => r.ok && r.json())
          .then(data => {
            setIsLoading(false);
            dispatch(setEditListing({ listingId, data }))
          })
          .catch(e => {
            setIsLoading(false);
            console.log('FetchContainer ', e)
          })
      }
    }
  }, [listingId, type, payload])

  return <div>
    {isLoading
      ?
      <div>
        <Card>
          <Result
            status="info"
            title="Please wait while we are fetching the listing"
          />
        </Card>
      </div>
      : null}
  </div>
}