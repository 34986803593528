import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import AppLayout from './Layout/Layout';
import { RouterAuth } from './Auth/router';
import { RouterDashboard } from './Dashboard/router';
import { RouterProperties } from './Listings/router';

import { store } from './ReduxStore';
import { Provider } from 'react-redux';

import { ConfigProvider } from 'antd';

import { SmokeTestsAndConnectivity } from './SmokeTests';
import { WelcomePage } from './Welcome/WelcomePage';

import 'antd/dist/reset.css';
import './App.css';

let persistor = persistStore(store);

const App = () => (
  <ConfigProvider
    theme={{
      hashed: false,
    }}
  >
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <SmokeTestsAndConnectivity />
        <AppLayout>
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route
              path="*"
              element={
                <>
                  <RouterAuth />
                  <RouterDashboard />
                  <RouterProperties />
                </>
              }
            />
          </Routes>
          <Outlet />
        </AppLayout>
      </Router>
      </PersistGate>
    </Provider>
  </ConfigProvider>
);

export default App;
