import { Button } from "antd";
import {
  FaBookOpen,
  FaPlus,
  FaSave,
  FaSearch,
  FaTrash,
  FaCaretRight,
  FaCaretLeft,
  FaCog,
  FaEllipsisV,
  FaTable,
  FaIdCard,
  FaEdit,
  FaFacebook,
  FaViber,
  FaUserAlt,
  FaDoorOpen,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { routerLinks } from "../helpers/links";

export const UIBtnSearch = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaSearch />}
    onClick={props.onClick}
    title={'FaSearch'}
  />;

export const UIBtnTrash = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaTrash />}
    onClick={props.onClick}
    title={'FaTrash'}
  />;

export const UIBtnFilter = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaBookOpen />}
    onClick={props.onClick}
    title={'FaBookOpen'}
  />;

export const UIBtnSave = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaSave />}
    onClick={props.onClick}
    title={'FaSave'}
  />;

export const UIBtnInsert = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaPlus />}
    onClick={props.onClick}
    title={'FaPlus'}
  />;

export const UIBtnNext = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaCaretRight />}
    onClick={props.onClick}
    title={'faCaretLeft'}
  />;

export const UIBtnPrev = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaCaretLeft />}
    onClick={props.onClick}
    title={'FaCaretLeft'}
  />;

export const UIBtnOptions = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaCog />}
    onClick={props.onClick}
    title={'FaCog'}
  />;

export const UIBtnEllipsisV = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaEllipsisV />}
    onClick={props.onClick}
    title={'FaEllipsisV'}
  />;

export const UIBtnTable = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaTable />}
    onClick={props.onClick}
    title={'FaTable'}
  />;

export const UIBtnCard = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaIdCard />}
    onClick={props.onClick}
    title={'FaRegCreditCard'}
  />;

export const UIBtnEdit = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaEdit />}
    onClick={props.onClick}
    title={'FaEdit'}
  />;

export const UIBtnFacebook = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaFacebook />}
    onClick={props.onClick}
    title={'FaFacebook'}
  />;

export const UIBtnViber = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaViber />}
    onClick={props.onClick}
    title={'FaViber'}
  />;

  export const UIBtnUserProfile = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaUserAlt />}
    onClick={props.onClick}
    title={'FaUserAlt'}
  />;

  export const UIBtnLogout = props =>
  <Button
    {...props}
    type={props.type || 'text'}
    icon={<FaDoorOpen/>}
    onClick={props.onClick}
    title={'FaDoorOpen'}
  />;

export const UIBtnLinkEditListing = props =>
  <Link to={routerLinks.edit(props.listingId)} {...props} >
    <UIBtnEdit type={props.type || 'text'} {...props} />
  </Link>;