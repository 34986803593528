//https://www.thebalance.com/home-amenities-features-home-buyers-1798249

// import { fetchLanguage } from '../helpers/http';
const fetchLanguage = (langId, type) => Promise.resolve(`tr-${langId}-${type}`)


export const PropertyDetails = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'PropertyDetails');

  return Promise.resolve({
    header: 'Property Details',
    Price: 'Price',
    Size: 'Size',
    SaleType: 'Type of sale',
  })
}

export const GeoLocation = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'GeoLocation');

  return Promise.resolve({
    header: 'GeoLocation',
    TextAddress: 'Address',
    Lat: 'Latitude',
    Lng: 'Longitude'
  })
}

export const BasicDetails = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'BasicDetails');

  return Promise.resolve({
    header: 'Basic Details',
    Code: 'Code',
    DateOfEntry: 'Date of entry',
    Collaborators: 'Collaborators'
  })
}

export const TypesOfLocations = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'TypesOfLocations');

  return Promise.resolve({
    header: 'Type of locations',
    ViewOfCoast: 'View of coast',
    ViewOfCity: 'View of city',
    ViewOfHills: 'View of hills',
    WaterfrontRiver: 'Waterfront river',
    WaterfrontOcean: 'Waterfront ocean',
    WaterfrontLakefront: 'Waterfront lakefront',
    Greenbelt: 'Greenbelt',
    GolfCourse: 'Golf course',
    Suburban: 'Suburban',
    City: 'City',
    RaisedElevation: 'Raised elevation',
    RasedMountains: 'Raised elevation',
    CulDeSac: 'Cul-de-sac',
    DeadEndStreet: 'Dead-end street',
    GatedCommunity: 'Gated community',
  });
}

export const HomeConstruction = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'HomeConstruction');

  return Promise.resolve({
    header: 'Home Construction',
    Adobe: 'Adobe',
    Brick: 'Brick',
    ConcreteBlock: 'Concrete block',
    Log: 'Log',
    Metal: 'Metal',
    Stone: 'Stone',
    Straw: 'Straw',
    Wood: 'Wood',
  });
}

export const HomeExterior = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'HomeExterior');

  return Promise.resolve({
    header: 'Home exterior',
    Brick: 'Brick',
    CementSiding: 'Cement siding',
    LapSiding: 'Lap siding',
    MetalSiding: 'Metal siding',
    VinylSiding: 'Vinyl siding',
    Shingle: 'Shingle',
    Stone: 'Stone',
    Stucco: 'Stucco',
    Veneer: 'Veneer',
    Wood: 'Wood',
  });
}

export const StyleOfHome = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'StyleOfHome');

  return Promise.resolve({
    header: 'Home style',
    AFrame: 'A-frame',
    Bungalow: 'Bungalow',
    Colonial: 'Colonial',
    Contemporary: 'Contemporary',
    Cottage: 'Cottage',
    Dome: 'Dome',
    Log: 'Log',
    Mediterranean: 'Mediterranean',
    Ranch: 'Ranch',
    Spanish: 'Spanish',
    Tudor: 'Tudor',
    Victorian: 'Victorian',
  })
}

export const Roof = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'Roof');

  return Promise.resolve({
    header: 'Roof',
    CompositionShingle: 'Composition shingle',
    ConcreteTile: 'Concrete tile',
    Metal: 'Metal',
    Rock: 'Rock',
    Shake: 'Shake',
    Slate: 'Slate',
    Tar: 'Tar',
    Tile: 'Tile',
    Wood: 'Wood',
  });
}

export const Yard = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'Yard');

  return Promise.resolve({
    header: 'Yard',
    SizeOfLot: 'Size of lot',
    SwimmingPool: 'Swimming pool',
    SportPool: 'Sport pool',
    Spa: 'Spa',
    Sauna: 'Sauna',
    SteamRoom: 'Steam room',
    Fireplace: 'Fireplace',
    FirePit: 'Fireplace or fire pit',
    BuiltInBBQ: 'Built-in BBQ',
    OutdoorKitchen: 'Outdoor kitchen',
    Courtyard: 'Courtyard',
    PatioCovered: 'Patio (covered)',
    PationUncovered: 'Patio (uncovered)',
    Deck: 'Deck',
    TennisCourts: 'Tennis courts',
    TreesAndLandscaping: 'Trees and landscaping',
    Gardens: 'Gardens',
    Lawn: 'Lawn',
    AutomaticSprinklers: 'Automatic sprinklers/drip/misting system',
  });
}

export const TypeOfHome = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'TypeOfHome');

  return Promise.resolve({
    header: 'Type of home',
    SingleFamily: 'Single Family',
    Attached: 'Attached',
    Detached: 'Detached',
    Duplex: 'Duplex',
    Halfplex: 'Halfplex',
    Condo: 'Condo',
    TownHome: 'Townhome',
    ManufacturedHome: 'Manufactured Home',
  });
}

export const TypeOfHomeFeatures = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'TypeOfHomeFeatures');

  return Promise.resolve({
    header: 'Home features',
    AgeBuilt: 'Age of home/year built',
    NumberOfBedrooms: 'Number of bedrooms',
    NumberOfBaths: 'Number of baths',
    NumberOfStoriesOrLevels: 'Number of stories or levels',
    UtilitiesGas: 'Gas',
    UtilitiesElectric: 'Electric',
    Parking: 'Parking/garage',
    garage: 'Parking/garage',
    RVParking: 'RV parking',
    BoatStorage: 'RV parking',
  });
}

export const HVAC = (langId = 'en') => {
  if (langId !== 'en') return fetchLanguage(langId, 'HVAC');

  return Promise.resolve({
    header: 'HVAC',
    Floor: 'Floor',
    Wall: 'Wall',
    AirConditioning: 'Air conditioning',
    CentralHeating: 'Central heating',
    Propane: 'Propane',
    Gravity: 'Gravity',
  })
}

export const TypeOfRooms = (langId = 'en') => {
    if (langId !== 'en') return fetchLanguage(langId, 'TypeOfRooms');

    return Promise.resolve({
      header: 'Rooms and spaces',
      NumberOfMasterBedrooms: 'Number of masters',
      NumberOfJuniorMasters: 'Number of junior masters',
      NumberOfEnsuites: 'Number of ensuites',
      Loft: 'Loft',
      Den: 'Den',
      BonusRoom: 'Bonus room',
      GreatRoom: 'Great room',
      HomeOfficeArea: 'Home office area',
      HomeTheater: 'Home theater',
      MediaRoom: 'Media room',
      FamilyRoom: 'Family room',
      GymWorkoutRoom: 'Gym/workout room',
      Library: 'Library',
      ButlerPantry: `Butler's pantry`,
      Sunroom: 'Sunroom',
      DownstairsBedroom: `Downstairs' bedroom`,
      Basement: 'Basement',
      GuestQuarters: 'Guest quarters',
      WineStorage: 'Wine storage',
    });
  }

  export const Flooring = (langId = 'en') => {
    if (langId !== 'en') return fetchLanguage(langId, 'Flooring');

    return Promise.resolve({
      header: 'Flooring',
      Carpeting: 'Carpeting',
      Concrete: 'Concrete',
      Bamboo: 'Bamboo',
      Stone: 'Stone',
      Tile: 'Tile',
      Laminate: 'Laminate',
      Cork: 'Cork',
      VinylLinoleum: 'Vinyl / linoleum',
      ManufacturedWood: 'Manufactured wood',
      Marble: 'Marble',
      Wood: 'Wood',
      WaterproofVinylPlanks: 'Waterproof vinyl planks',
    });
  }

  export const KitchenAppliances = (langId = 'en') => {
    if (langId !== 'en') return fetchLanguage(langId, 'KitchenAppliances');

    return Promise.resolve({
      header: 'Kitchen appliances',
      BuiltIn: 'Built in',
      Freestanding: 'Frees tanding',
      Stainless: 'Stainless',
      Colored: 'Colored',
      CabinetryMatch: 'Cabinetry Match',
    });
  };

  export const KitchenCounters = (langId = 'en') => {
    if (langId !== 'en') return fetchLanguage(langId, 'KitchenCounters');

    return Promise.resolve({
      header: 'Kitchen counter',
      CastIron: 'Cast Iron',
      Ceramic: 'Ceramic',
      Granite: 'Granite',
      LaminateOrSynthetic: 'Laminate or synthetic',
      Marble: 'Marble',
      OtherMaterial: 'Other material',
      OtherMetal: 'Other metal',
      Steel: 'Steel',
      Stone: 'Stone',
      Wood: 'Wood',
    });
  }

  export const KitchenFeatures = (langId = 'en') => {
    if (langId !== 'en') return fetchLanguage(langId, 'KitchenFeatures');

    return Promise.resolve({
      header: 'Kitchen features',
      UnderCabinetLighting: 'UnderCabinetLighting',
      RecessedLighting: 'RecessedLighting',
      Pendants: 'Pendants',
      DiningBars: 'DiningBars',
      Islands: 'Islands',
      WetBars: 'WetBars',
      Remodeled: 'Remodeled',
      WarmingDrawers: 'Warming drawers',
      WineRefrigerators: 'Wine refrigerators',
      TrashCompactors: 'Trash compactors',
      FarmStyleSinks: 'Farm style sinks',
      DualSinks: 'Dual sinks',
      TripleSinks: 'Triple Sinks',
    });
  }

  export const DiningFeatures = (langId = 'en') => {
    if (langId !== 'en') return fetchLanguage(langId, 'DiningFeatures');

    return Promise.resolve({
      header: 'Dining features',
      SpaceInKitchen: 'Space in kitchen',
      BreakfasNook: 'breakfast nook',
      DiningCombination: 'Dining combination',
      LivingCombination: 'Living combination',
      FormalDiningRoom: 'Formal dining room',
      OutdoorKitchen: 'Outdoor kitchen',
    });
  }

  export const MasterSuiteAmenities = (langId = 'en') => {
    if (langId !== 'en') return fetchLanguage(langId, 'MasterSuiteAmenities');

    return Promise.resolve({
      header: 'Master suite',
      Small: 'Small',
      Large: 'Large',
      Balcony: 'Balcony',
      OutsideAccess: 'Outside access',
      PrivatePatio: 'Private patio',
      RemoteArea: 'Remote area',
      SittingRoom: 'Sitting room',
      Office: 'Office',
      GroundFloor: 'Ground floor',
      SecondFloor: 'Second floor',
      SurroundSound: 'Surround sound',
      Fireplace: 'Fireplace',
      WetBar: 'Wet bar',
      DualWalkInClosets: 'Dual walk-in closets',
    });
  }

  export const BathFeatures = (langId = 'en') => {
    if (langId !== 'en') return fetchLanguage(langId, 'BathFeatures');

    return Promise.resolve({
      header: 'Bath features',
      DualSinks: 'Dual sinks',
      TripleSinks: 'Triple sinks',
      BuiltInDressingVanities: 'Built-in dressing vanities',
      VanityShelves: 'Vanity shelves',
      MirrorsInShowers: 'Mirrors in showers/tubs',
      JettedOrSunkenTubs: 'Jetted or sunken tubs',
      SeparateShower: 'Separate shower',
      RainshoweHeads: 'Rainshower heads',
      StoneSurfaces: 'Stone surfaces',
      Tilesurfaces: 'Tile surfaces',
      OutsideAccess: 'Outside access',
      Skylights: 'Skylights',
      HeatedFloors: 'Heated floors',
      Remodeled: 'Remodeled / updated',
    });
  }

  export const HomeEnergyAmenitiesAndFeatures = (langId = 'en') => {
    if (langId !== 'en') return fetchLanguage(langId, 'HomeEnergyAmenitiesAndFeatures');

    return Promise.resolve({
      header: 'Energy features',
      AtticFans: 'Attic fans',
      CeilingFans: 'Ceiling fans',
      DualPaneWindows: 'Dual pane windows',
      TriplePaneWindows: 'Triple pane windows',
      ProgrammableThermostats: 'Programmable thermostats',
      SingleFlushToilets: 'Single flush toilets',
      WindowShutters: 'Window shutters',
      SolarHeat: 'Solar heat',
      SolarPlumbing: 'Solar plumbing',
      SolarScreens: 'Solar Screens',
      StormWindows: 'Storm windows',
      TanklessWaterHeater: 'Tankless water heater',
      SkylightsOrSkyTubes: 'Skylights or sky tubes',
      WholeHouseFan: 'Whole house fan',
    });
  }

  export const AccessibilityFeatures = (langId = 'en') => {
    if (langId !== 'en') return fetchLanguage(langId, 'AccessibilityFeatures');

    return Promise.resolve({
      header: 'Accesibility features',
      ExtraWideDoorways: 'Extra-wide doorways',
      Ramps: 'Ramps',
      GrabBars: 'Grab bars',
      LowerCounterHeights: 'Lower counter heights',
      WalkInTubsAndShowers: 'Walk-in tubs and showers',
    });
  }
