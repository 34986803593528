import { useSelector } from 'react-redux';
import { Alert } from 'antd';
import { Register } from '../Components/Register';
import { YouAreAlreadyLoggedIn } from '../Components/YouAreAlreadyLoggedIn';

export const RegisterContainer = () => {
  const loggedIn = useSelector(state => state.auth.token !== null);

  return (
    (loggedIn === true)
      ? <Alert message={<YouAreAlreadyLoggedIn />} type="warning" />
      : <Register />
  )
}
