import { useDispatch, useSelector } from "react-redux";
import { updateEditListingKeyVal } from "../ReduxSlices/ListingsSlice";
import { GoogleMapComponent } from "../Components/GoogleMapComponent";
import { Input, Space, Row, Col, notification } from "antd";
import { useState } from "react";
import { serviceLinks } from "../../mainConfig";
import { translate } from "../translations/generic";
import { UIBtnSearch } from '../Components/UIComponents'

export const GoogleMapEditContainer = ({
  listingId,
  withSearch = true,
}) => {
  const [searchTextGeocoding, setSearchTextGeocoding] = useState('');
  const marker = useSelector(state => state.listings.editListing[listingId].GeoLocation);
  const dispatch = useDispatch();

  const search = async () => {
    try {
      const response = await fetch(
        serviceLinks.geocodingSearch({ address: searchTextGeocoding })
      );
      const data = await response.json();

      if (data.status === 'OK') {
        dispatch(updateEditListingKeyVal({
          listingId,
          groupId: 'GeoLocation',
          fieldId: 'Lat',
          value: data.results[0].geometry.location.lat
        }))

        dispatch(updateEditListingKeyVal({
          listingId,
          groupId: 'GeoLocation',
          fieldId: 'Lng',
          value: data.results[0].geometry.location.lng
        }))

        notification.success({
          message: translate('geolocationSuccess')
        });
      }
    } catch (e) {
      notification.error({
        message: translate('geolocationError')
      });
      console.log(e);
    }
  }

  return (
    <Row gutter={[16, 16]}>
      {withSearch &&
        <Col xs={24}>
          <h3>{translate('geolocationSearchHeader')}</h3>
          <Space.Compact style={{ width: '100%' }}>
            <Input
              type="text"
              placeholder="Enter an address"
              onChange={e => setSearchTextGeocoding(e.target.value)}
              onKeyUp={e => (e.key === 'enter') ? search() : null}
            />
            <UIBtnSearch 
              type="primary"
              onClick={search}
            />
          </Space.Compact>
        </Col>
      }
      <Col xs={24}>
        <GoogleMapComponent
          markers={[marker]}
          currentPositionMarker={{ lat: marker.Lat, lng: marker.Lng }}
          addMarker={f => {
            dispatch(
              updateEditListingKeyVal({
                listingId,
                groupId: 'GeoLocation',
                fieldId: 'Lat',
                value: f.latLng.lat()
              })
            )
            dispatch(
              updateEditListingKeyVal({
                listingId,
                groupId: 'GeoLocation',
                fieldId: 'Lng',
                value: f.latLng.lng()
              })
            )
          }}
          updateDraggableMarker={f => {
            dispatch(
              updateEditListingKeyVal({
                listingId,
                groupId: 'GeoLocation',
                fieldId: 'Lat',
                value: f.latLng.lat()
              })
            )
            dispatch(
              updateEditListingKeyVal({
                listingId,
                groupId: 'GeoLocation',
                fieldId: 'Lng',
                value: f.latLng.lng()
              })
            )
          }}
        />
      </Col>
    </Row>
  )
}