import { Checkbox, Slider } from "antd"
import { useSelector, useDispatch } from "react-redux";
import { setSearchFilter } from '../ReduxSlices/ListingsSlice';


const CheckBoxSearchRedux = ({ groupId, fieldId, label }) => {
  const value = useSelector(state => state.listings.searchFilter[groupId][fieldId])
  const dispatch = useDispatch();

  return (
    <div>
      <Checkbox
        checked={value}
        style={{
          marginRight: '0.5rem'
        }}
        onChange={e => {
          dispatch(setSearchFilter({
            groupId,
            fieldId,
            value: e.target.checked
          }))
        }}
      />
      {label}
    </div>
  )
}

export const CheckBoxSearch = ({ groupId, labels, items }) => (
  items.map((item, idx) => <CheckBoxSearchRedux key={item.key} groupId={groupId} fieldId={item.key} label={labels[item.key]} />)
)

const SliderSearchRedux = ({ groupId, label, fieldId, min = 0, max = 10 }) => {
  const value = useSelector(state => state.listings.searchFilter[groupId][fieldId])
  const dispatch = useDispatch();

  return (
    <div>
      {label} 
      <Slider
        range
        min={min}
        max={max}
        value={value}
        onChange={value => {
          console.log('the value is', value)
          dispatch(setSearchFilter({
            groupId,
            fieldId,
            value: [value[0], value[1]]
          }))
        }}
      />
    </div>
  )
}

export const SliderSearch = ({ groupId, labels, items }) =>
  items.map((item, idx) =>
    <SliderSearchRedux
      key={item.key}
      groupId={groupId}
      fieldId={item.key}
      label={labels[item.key]}
    />
  )