import { useState } from 'react';
import { Button, Card, Col, Input, notification, Row } from 'antd';
import md5 from 'md5';

import { askForRegistrationEmail } from '../helpers/registration';
import { conf } from '../config';
import { useNavigate } from 'react-router-dom';
import { routerLinks } from '../helpers/links';
import { translate } from '../../Listings/translations/generic';

export const Register = () => {
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [userName, setUserName] = useState('');
  const [fullName, setFullName] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const navigate = useNavigate();

  const onRegister = async () => {
    if (password1 !== password2) return notification.error({ message: 'Passwords do not match' });
    notification.info({ key: 'registrationProcess', message: 'Please wait while we are creating your account' });

    fetch(conf.createUserUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: userName,
        password: md5(password1),
        fullName: fullName,
      })
    }).then(r => r.ok && r.json())
      .then(d => {
        if (d.error && d.error.includes('insertOne')) {
          return notification.error({
            key: 'registrationProcess',
            message: 'User alread exists'
          });
        }

        navigate(routerLinks.forgotPassword);
        notification.success({ key: 'registrationProcess', message: 'Account created succesfully' });
        askForRegistrationEmail({ email: userName })
          .then(r => r.ok && notification.success({ key: 'registrationProcess', message: 'Please check your e-mail' }));
      });
  };

  return (
    <Card
      title={translate('register')}
    >
      <Row gutter={[8, 8]} >
        <Col span={24}>
          <h4>{translate('email')}</h4>
          <Input
            placeholder="Please enter your e-mail (You will receive an email)"
            type="text"
            onChange={e => setUserName(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <h4>Full name</h4>
          <Input
            type="text"
            placeholder="Please enter your full name (i.e. John Smith)"
            onChange={e => setFullName(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <h4>Password</h4>
          <Input.Password
            placeholder='Please select a password'
            visibilityToggle={{ visible: passwordVisible1, onVisibleChange: setPasswordVisible1 }}
            onChange={e => setPassword1(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <h4>Password</h4>
          <Input.Password
            placeholder='Please retype your password'
            visibilityToggle={{ visible: passwordVisible2, onVisibleChange: setPasswordVisible2 }}
            onChange={e => setPassword2(e.target.value)}
          />
        </Col>
        <Col span={24} className="alRight">
          <Button
            type="primary"
            onClick={onRegister}
          >
            Register
          </Button>
        </Col>
      </Row>
    </Card>
  );
};