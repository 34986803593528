import { Routes, Route } from 'react-router-dom';

import { routerLinks } from './helpers/links';
import LoginPage from './Pages/LoginPage';
import RegisterPage from './Pages/RegisterPage';
import LogoutPage from './Pages/LogoutPage';
import CheckYourEmailPage from './Pages/CheckYourEmail';
import ForgotYourPasswordPage from './Pages/ForgotYourPassword';

export const RouterAuth = () => (
  <Routes>
    <Route path={routerLinks.login()} element={<LoginPage />} />
    <Route path={routerLinks.register()} element={<RegisterPage />} />
    <Route path={routerLinks.logout()} element={<LogoutPage />} />
    <Route path={routerLinks.checkYourEmail()} element={<CheckYourEmailPage />} />
    <Route path={routerLinks.forgotPassword()} element={<ForgotYourPasswordPage />} />
  </Routes>
)
