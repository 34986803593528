import {combineReducers} from "redux"; 
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './Auth/ReduxSlices/AuthSlice'
import listingsReducer from './Listings/ReduxSlices/ListingsSlice'
import layoutReducer from './Layout/ReduxSlices/LayoutSlice'
import thunk from 'redux-thunk'

const reducers = combineReducers({
  auth: authReducer,
  listings: listingsReducer,
  layout: layoutReducer,
 });

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production',
})