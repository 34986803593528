import { notification } from "antd";
import { useEffect, useState } from "react"
import { servers } from "./Auth/config";

const states = {
  working: 0,
  success: 1,
  fail: 2,
  offLine: 300,
  onLine: 400,
};

export const SmokeTestsAndConnectivity = () => {
  const [status, setStatus] = useState(states.working);

  window.addEventListener('offline', (e) => setStatus(states.offLine));

  window.addEventListener('online', (e) => setStatus(states.onLine));

  const runSmokeTests = async () => {
    Promise.all(
      Object.keys(servers).map(async url => {
        console.log('Checking health for :',servers[url] + '/health?id=' + Math.random())
        return fetch(servers[url] + '/health?id=' + Math.random())
      })
    ).then((success) => setStatus(states.success))
      .catch(e => setStatus(states.fail))
  }

  useEffect(() => {
    runSmokeTests();
  }, [])

  useEffect(() => {
    if (status === states.working) return notification.info({ key: 'smokeTests', message: 'Please wait, setting up your system' });
    if (status === states.success) return notification.success({ key: 'smokeTests', message: 'Your system is ready' });
    if (status === states.onLine) return notification.success({ key: 'smokeTests', message: 'Internet is back online' });
    if (status === states.fail) return notification.error({ key: 'smokeTests', message: 'Your system is not ready. Please contact technical support' });
    if (status === states.offLine) return notification.error({ key: 'smokeTests', message: 'Internet connection is down or very poor' });
  }, [status])

  return (
    <></>
  )
}
