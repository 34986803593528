import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: false,
  token: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.token += action.payload;
      state.isLoggedIn = true;
    },
    clearToken: (state) => {
      state.token = null;
      state.isLoggedIn = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setAuthToken, clearToken } = authSlice.actions

export default authSlice.reducer