import { serviceLinks } from "../helpers/links"

const httpRequest = async request => {
  let body = {};
  let bearer = localStorage.token;

  
  const options = {
    method: request.method,
    mode: request.cors || 'cors',
    headers: {
      "Content-Type": "application/json",
      bearer,
    },
    body
  }
  if ((request.method === 'POST') && (request.payload)) {
    options.body = JSON.stringify(request.payload);
  } else {
    delete options.body;
  }
  
  try {
    const response = await fetch(
      request.url,
      options
    );

    return response;
  } catch (e) {
    return {
      status: 400,
      OK: false,
      e,
      message: 'bad request'
    }
  }
}

export const fetchListingById = ({ listingId }) =>
  httpRequest({ url: serviceLinks.edit(listingId) })

export const searchListings = ({ filter }) =>
  httpRequest(
    {
      url: serviceLinks.query(),
      method: 'POST',
      payload: filter
    }
  )

export const saveOrUpdateListing = ({ listing }) =>
  httpRequest({
    url: serviceLinks.saveOrUpdate(),
    method: 'POST',
    payload: listing
  })