import { useEffect } from "react"
import { searchListings } from "../api/ListingsAPI";

export const DisplayLatestListings = ({ setListings }) => {
  useEffect(() => {
    searchListings({ filter: {} })
      .then(r => r.ok && r.json())
      .then(data => setListings(data))
      .catch(e => console.log(e))
  }, []);

  return (
    <></>
  )
}