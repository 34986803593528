import { useState } from 'react';
import { Button, Card, Col, Input, notification, Row, Space } from 'antd';
import { useDispatch } from 'react-redux';
import md5 from 'md5';

import { conf } from '../config';
import { Link, useNavigate } from 'react-router-dom';
import { setAuthToken } from '../ReduxSlices/AuthSlice';
import { routerLinks } from '../helpers/links';
import { translate } from '../../Listings/translations/generic';

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const onLogin = () => {
    notification.info({ key: 'loginProcess', message: 'Please wait while verifying your credentials' });

    const url = `${conf.authServer}/login?email=${userName}&password=${md5(password)}`;
    fetch(url)
      .then(r => r.ok && r.json())
      .then(d => {
        if ((d.error) || (!d.token)) return notification.warning({ key: 'loginProcess', message: 'Could not login please check your credentials' });
        notification.success({ key: 'loginProcess', message: 'Welcome ' });
        console.log(d);
        localStorage.token = d.token;
        dispatch(setAuthToken(d.token));
        navigate('/dashboard');
      })
      .catch(e => console.log('error', e));
  };

  return (
    <Card
      title={translate('login')}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <h4>Username</h4>
          <Input
            autoFocus
            type="text"
            placeholder="Please enter your e-mail"
            onChange={e => setUserName(e.target.value)}
            onKeyUp={e => e.code === 'Enter' && onLogin()}
          />
        </Col>
        <Col span={24}>
          <h4>Password</h4>
          <Input.Password
            placeholder='Please enter your password'
            visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
            onChange={e => setPassword(e.target.value)}
            onKeyUp={e => e.code === 'Enter' && onLogin()}
          />
        </Col>
        <Col span={24}>
          <Space direction="horizontal" align="center" >
            <Button
              type="primary"
              onClick={onLogin}
            >Login
            </Button>
            <Link to={routerLinks.forgotPassword()}>Forgot password ?</Link>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};
