import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { config } from '../../mainConfig'

export const GoogleMapComponent = ({
  getLangId = 'en',
  gMapsKey = config.gMapsKey,
  markers = [],
  addMarker,
  currentPositionMarker,
  onClickMarker,
  updateDraggableMarker,
  polygonVisible = false,
}) => {
  const [polygonPath, setPolygonPath] = useState([]);

  const centerInEurope = {
    lat: -34.397,
    lng: 150.644
  };

  useEffect(() => {
    const polyPath = markers.map(marker => ({
      lat: marker.lat,
      lng: marker.lng
    }))
    setPolygonPath(polyPath);
  }, [markers]);

  const getCurrentCenter = () => {
    if ((currentPositionMarker.lat) && (currentPositionMarker.lng)) {
      return {
        lat: currentPositionMarker.lat,
        lng: currentPositionMarker.lng
      }
    }
    return centerInEurope;
  }

  return (
    <LoadScript
      language={getLangId}
      googleMapsApiKey={gMapsKey}
    >
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={getCurrentCenter()}
        zoom={13}
        onClick={(e) => addMarker(e)}
      >
        <></>
        {polygonVisible && polygonPath.length > 0 &&
          <Polyline path={polygonPath} />
        }
        {markers && markers.map((marker, idx) => (
          <Marker
            key={idx}
            label={`${idx + 1}`}
            draggable
            position={{ 
              lat: parseFloat(marker.Lat),
              lng: parseFloat(marker.Lng)
            }}
            onClick={onClickMarker}
            clickable={true}
            onDragEnd={e => updateDraggableMarker(e, marker.lat, marker.lng)}
          >
            <div>{idx}</div>
          </Marker>
        ))
        }
      </GoogleMap>
    </LoadScript>
  )
}

