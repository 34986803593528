import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';
import { routerLinks } from '../helpers/links';


export const CheckYourEmail = () =>
  <Result
    status="success"
    title="Registration succesfull"
    subTitle="Please check your email"
    extra={
      <Link to={routerLinks.login()}>
        <Button type="primary">Back Home</Button>
      </Link>
    }
  />
