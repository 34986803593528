import React from 'react';
import { Layout, Space, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useResolvedPath } from 'react-router-dom';

import './Layout.css';
import { UIBtnLogout, UIBtnUserProfile } from '../Listings/Components/UIComponents';
import { FaDoorOpen, FaUserAlt } from 'react-icons/fa';

const { Header, Content, Footer, } = Layout;

const items = [
  {
    key: '1',
    label: (
      <Link to="/logout">
        <UIBtnLogout /> Logout
      </Link>
    ),
  },
];

const AppLayout = ({ children }, props) => {
  const path = useResolvedPath();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const pathClass = path.pathname.substring(1).replace(/[/]/g, '-');

  return (
    <div className={`site-layout ${pathClass}`}>
      <Header className="Header">
        {isLoggedIn === false &&
          <>
            <Link to='/login'>Login</Link>
            <span> </span>
            <Link to='/register'>Register</Link>

          </>
        }
        {isLoggedIn &&
          <>
            <Link to='/listings'>Listings</Link>
          </>
        }
        {isLoggedIn &&
          <Space direction='horizontal' style={{ float: 'right' }}>
            <span id="navPortal"></span>
            <Dropdown menu={{ items }}>
                <UIBtnUserProfile />
            </Dropdown>
          </Space>
        }
      </Header>
      <Content className="Content">
        {children}
      </Content>
      <Footer className="Footer">
        SEKDev.com 2003 - {new Date().toISOString().substring(0, 4)}
      </Footer>
    </div>
  );
};

export default AppLayout;