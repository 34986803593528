import { Checkbox, Col, Input, Row, Slider } from "antd"
import { useSelector, useDispatch } from "react-redux";
import { updateEditListingKeyVal } from '../ReduxSlices/ListingsSlice';

const CheckBoxEditRedux = ({ listingId, groupId, fieldId, label }) => {  
  const value = useSelector(state => {
    return state.listings.editListing[listingId][groupId][fieldId]
  })
  const dispatch = useDispatch();
  return (
    <Col xs={24} md={8}>
      <Checkbox
        checked={value}
        style={{
          marginRight: '0.5rem'
        }}
        onChange={e => {
          dispatch(updateEditListingKeyVal({
            listingId,
            groupId,
            fieldId,
            value: e.target.checked
          }))
        }}
      />
      {label}
    </Col>
  )
}

export const CheckBoxEdit = ({ listingId, groupId, labels, items }) => (
  <Row gutter={[16,16]}>
    {items.map((item, idx) => <CheckBoxEditRedux
      key={item.key}
      listingId={listingId}
      groupId={groupId}
      fieldId={item.key}
      label={labels[item.key]}
    />)}
  </Row>
)

const SliderEditRedux = ({ listingId, groupId, label, fieldId, min = 0, max = 10 }) => {
  const value = useSelector(state => state.listings.editListing[listingId][groupId][fieldId])
  const dispatch = useDispatch();

  return (
    <Col xs={24} md={8}>
      {label}
      <Slider
        range
        min={min}
        max={max}
        value={value}
        onChange={value => {
          dispatch(updateEditListingKeyVal({
            listingId,
            groupId,
            fieldId,
            value: [value[0], value[1]]
          }))
        }}
      />
    </Col>
  )
}

export const SliderEdit = ({ listingId, groupId, labels, items }) =>
  <Row gutter={[16,16]}>
    {items.map((item, idx) =>
      <SliderEditRedux
        key={item.key}
        listingId={listingId}
        groupId={groupId}
        fieldId={item.key}
        label={labels[item.key]}
      />
    )}
  </Row>

export const InputEdit = ({ listingId, groupId, label, fieldId, min = 0, max = 10 }) => {

  const value = useSelector(state => {
    console.log('bbb',state.listings)
    return state.listings.editListing[listingId][groupId][fieldId]
  })
  const dispatch = useDispatch();

  return (
    <Col xs={24} md={8}>
      {label}
      <Input
        type="number"
        min={min}
        max={max}
        value={value}
        onChange={e => {
          console.log('the value is', e.target.value)
          dispatch(updateEditListingKeyVal({
            listingId,
            groupId,
            fieldId,
            value: parseInt(e.target.value)
          }))
        }}
      />
    </Col>
  )
}