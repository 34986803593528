import { servers } from '../config';

export const serviceLinks = {
  edit: id => `${servers.listingsAPIServer}/listing/${id}`,
  query: () => `${servers.listingsAPIServer}/listings`,
  saveOrUpdate: () => `${servers.listingsAPIServer}/listing`,
};

export const routerLinks = {
  search: () => `/listings`,
  edit: id => `/listing/${id}`,
  searchResults: () => `/listings/search/results`,
  insert: () => `/listings/insert`,
};